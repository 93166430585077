import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  // INFO: `callback` is a function,
  // `delay` can be a `number` or `null`. if delay === null, setInterval will be stopped
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay]);
};

export default useInterval;
