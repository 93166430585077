import { flow, types, getParent } from 'mobx-state-tree';
import Router from 'next/router';

import {
  completedRequestSGTCookieKey,
  requestSGTFormStepsMap,
  storeInitialSGTState,
  requestSGTFormSteps
} from '@constants/request-form';
import Logger from '@utils/logger';
import Api from '@utils/api';
import apiUrls from '@constants/api-urls';
import Cookies from '@utils/cookies';
import ga from '@utils/ga';

const changeRouterQuery = (requestId, step, tripId) => {
  Router.push({
    pathname: Router.asPath.replace(/\?.*/g, ''),
    query: requestId
      ? {
          trip: tripId,
          id: requestId,
          step
        }
      : {
          trip: tripId,
          step
        }
  });
};

const updateRouterQuery = (requestId, step, tripId) => {
  Router.replace({
    pathname: Router.asPath.replace(/\?.*/g, ''),
    query: requestId
      ? {
          trip: tripId,
          id: requestId,
          step
        }
      : {
          trip: tripId,
          step
        }
  });
};

export const RequestSGTFormStore = types
  .model('RequestSGTFormStore', {
    data: types.frozen({}),
    dataIsLoading: false,
    currentStep: types.enumeration(requestSGTFormStepsMap),
    selectOptions: types.frozen({})
  })
  .views((self) => ({
    get requestId() {
      return self.data.id;
    },
    cookiesRequestFormId() {
      return Cookies.get(completedRequestSGTCookieKey);
    },
    cookiesRequestFormIdReset() {
      return Cookies.remove(completedRequestSGTCookieKey);
    },
    currentStepIndex() {
      return requestSGTFormStepsMap.findIndex((step) => step === self.currentStep);
    }
  }))
  .actions((self) => ({
    startQuestionnaire() {
      self.currentStep = requestSGTFormSteps.trip;
      self.data = {};
      changeRouterQuery(undefined, 1, self.currentTrip);
    },
    setStep(step) {
      self.currentStep = step;
    },
    prevStep() {
      const prevIndexStep = self.currentStepIndex() - 1;
      self.currentStep = requestSGTFormStepsMap[prevIndexStep];
      changeRouterQuery(self.requestId, prevIndexStep, self.currentTrip);
    },
    nextStep() {
      const nextIndexStep = self.currentStepIndex() + 1;
      self.currentStep = requestSGTFormStepsMap[nextIndexStep];
      changeRouterQuery(self.requestId, nextIndexStep, self.currentTrip);
    },
    reset() {
      Object.keys(storeInitialSGTState).forEach((key) => (self[key] = storeInitialSGTState[key]));
    },

    setTrip(tripId, isUpdateRoute) {
      self.currentTrip = tripId;
      if (isUpdateRoute) updateRouterQuery(self.requestId, self.currentStepIndex(), tripId);
    },
    fetchTripsAutocomplete: flow(function* (input) {
      try {
        self.tripsListIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.autocomplete(input));
        // eslint-disable-next-line no-console
        self.tripsList = response.data.results;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.tripsListIsLoading = false;
      }
    }),
    fetchTrips: flow(function* () {
      try {
        self.tripsListIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.index);
        // eslint-disable-next-line no-console
        self.tripsList = response.data.results;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.tripsListIsLoading = false;
      }
    }),
    fetchTripData: flow(function* (tripId) {
      try {
        yield self.fetchTrips();
        self.currentTrip = tripId;
      } catch (e) {
        Logger.error(e);
      }
    }),
    createRequest: flow(function* (requestData) {
      const response = yield Api.post(apiUrls.SGTrequests.index, requestData);
      self.data = { ...self.data, ...response.data };
      Cookies.set(completedRequestSGTCookieKey, response.data.id);
      self.nextStep();
    }),

    updateRequest: flow(function* (requestData) {
      const response = yield Api.put(apiUrls.SGTrequests.item(self.requestId), {
        ...self.data,
        ...requestData
      });
      self.data = { ...self.data, ...response.data };
      if (self.currentStepIndex() < requestSGTFormStepsMap.length - 1) {
        self.nextStep();
      }
    }),

    fetchRequestData: flow(function* (requestFormId) {
      try {
        self.dataIsLoading = true;
        const response = yield Api.get(apiUrls.SGTrequests.item(requestFormId));
        self.data = { ...self.data, ...response.data };
        self.dataIsLoading = false;
      } catch (e) {
        Logger.error(e);
      }
    }),
    attachRequest: flow(function* () {
      const rootStore = getParent(self);
      const displayTripsPopup = rootStore?.userStore?.data?.display_trips_popup;

      const completedRequestId = self.cookiesRequestFormId();
      if (!completedRequestId) {
        return;
      }
      try {
        yield Api.post(apiUrls.SGTrequests.attach(completedRequestId));
      } catch (e) {
        Logger.error(e);
      } finally {
        if (displayTripsPopup) {
          ga.sendRegisteredUserNewTripLogin();
        } else {
          ga.sendRegisteredUserNewTrip();
        }
        Cookies.remove(completedRequestSGTCookieKey);
      }
    })
  }));
