import dayjs from 'dayjs';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import Router from 'next/router';

import { dateFormats } from '@constants/constants';
import routes from '@constants/routes';

const Helpers = {
  isClient() {
    return typeof window !== 'undefined';
  },
  formatSStoMMSS(seconds) {
    return dayjs().startOf('day').second(seconds).format(dateFormats.timer);
  },
  formatDateDiff(seconds) {
    const structure = [
      ['day', 86400],
      ['hour', 3600],
      ['minute', 60],
      ['second', 1]
    ];

    let delta = seconds;

    return structure.map(([key, durationValue]) => {
      const value = Math.floor(delta / durationValue);
      delta -= value * durationValue;
      return [key, value];
    });
  },
  formatExpirationDateDiff(seconds) {
    const structure = [
      ['week', 604800],
      ['day', 86400],
      ['hour', 3600]
    ];

    let delta = seconds;

    return structure.map(([key, durationValue]) => {
      const value = Math.floor(delta / durationValue);
      delta -= value * durationValue;
      return [key, value];
    });
  },
  filterRemainingTimeParts(remainTimeParts) {
    const weeks = remainTimeParts.find(([key]) => key === 'week');
    const days = remainTimeParts.find(([key]) => key === 'day');
    const hours = remainTimeParts.find(([key]) => key === 'hour');
    if (weeks[1] > 0 && days[1] > 0) return [weeks, days];
    if (weeks[1] > 0) return [weeks];
    if (days[1] > 0 && hours[1] > 0) return [days, hours];
    if (days[1] > 0) return [days];
    return [hours];
  },
  isAuthRoute(route) {
    return Object.values(routes.auth).includes(route);
  },
  redirectTo(server, to) {
    if (server) {
      server.writeHead(302, {
        Location: to
      });
      server.end();
    } else {
      Router.push(to);
    }
  },
  handleServerErrors(err) {
    let errors = {};
    if (err.response && err.response.data) {
      const { data } = err.response;
      if (isArray(data)) {
        return {
          non_field_errors: data.join(', ')
        };
      }
      if (typeof data !== 'string') {
        Object.keys(data).forEach((key) => {
          errors[key] = Array.isArray(data[key]) ? data[key].join(', ') : data[key];
        });
      } else {
        errors = data;
      }
    }
    return errors;
  },
  errorObjToString(obj) {
    if (isString(obj)) {
      return obj;
    }
    return Object.values(obj).join(', ');
  },
  cutString(string = '', chars, overflow = '...') {
    const overflowStr = string.length > chars ? overflow : '';
    return string.substr(0, chars) + overflowStr;
  },
  tinyNumber(amount) {
    if (!amount) {
      return 0;
    }
    if (amount < 1000) {
      return amount;
    } else {
      return `${parseFloat(amount / 1000).toFixed(1)}K`;
    }
  },
  tinyIndicatorNumber(number, max = 9) {
    return number > max ? `${max}+` : number;
  },
  mbToBytes(mb) {
    return mb * 1048576;
  },
  bytesToMb(bytes) {
    return (bytes / 1048576).toFixed(2);
  },
  getPagesNumber(count, pageSize = 6) {
    return Math.ceil(count / pageSize);
  },
  objToString(obj) {
    return Object.values(obj).join(', ');
  },
  valueIsNumeric(value) {
    const re = /^([0-9])*$/g;
    return re.test(value);
  },
  yupFieldLabelExtractor(schema) {
    return function (fieldName) {
      return get(schema, ['fields', fieldName, '_label']);
    };
  },
  getFileNameFromUrl(url = '') {
    if (!isString(url)) {
      return null;
    }
    return url.split('/').pop();
  },
  getEndofPath(url = '') {
    if (!isString(url)) {
      return null;
    }
    return url.split('/').at(-2);
  },
  getFileExtension(filename = '') {
    if (!isString(filename)) {
      return null;
    }
    return filename.split('.').pop();
  },
  getPersonName(item) {
    return `${item?.first_name} ${item?.last_name}`;
  },
  getTextFromHtml(html) {
    if (!this.isClient() || !html) {
      return '';
    } else {
      const el = document.createElement('div');
      el.innerHTML = html;
      return el.innerText;
    }
  },
  formatDate(date, format) {
    if (!date) {
      return 'Not provided';
    }
    return dayjs.utc(date).format(format);
  },
  appendNotificationsCounterToTitle(count) {
    if (!Helpers.isClient()) {
      return;
    }
    if (count) {
      // Timeout is required for handle next/head updating lag time
      setTimeout(() => {
        const titleContentWithoutCounter = document.title.replace(/^\(\d*\)\s\|\s/g, '');
        document.title = `(${count}) | ${titleContentWithoutCounter}`;
      }, 250);
    }
  },
  lineBreaksToBr(string) {
    if (!string || !isString(string)) {
      return '';
    }
    const removeExistingBreaks = string.replace(/<br \/>/g, '');
    return removeExistingBreaks.replace(/\r\n|\r|\n/g, '<br/>');
  },
  stringWithNumber(count, string) {
    if (count < 2) return `${count} ${string}`;

    return `${count} ${string}s`;
  },
  formatNumber(num) {
    return num ? num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num;
  },
  formatPriceStr(price) {
    if (!price) {
      return price;
    }
    return price.toString().indexOf('.') === -1 ? price : parseFloat(price).toFixed(2);
  },
  formatOptions(options, isAddAll) {
    if (!options) return [];
    const newOptions = options.map((option) => ({
      value: option.id,
      label: option.name
    }));

    return isAddAll ? [{ label: 'All', value: '' }, ...newOptions] : newOptions;
  },
  showProgressBar(url) {
    const doNotShowFor = [routes.auth.login.index, routes.auth.signUp.index];

    return doNotShowFor.includes(url);
  },
  isCraftingFeeHaveBeingPaid(tripPaymentDetails) {
    return tripPaymentDetails?.crafting_fee === 0 || Boolean(tripPaymentDetails?.crafting_fee_has_been_paid);
  },
  capitalizeSingleWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  },
  commarize(value) {
    // Alter numbers larger than 1k
    if (value >= 1e3) {
      const units = ['K', 'M', 'B', 'T'];

      // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
      const unit = Math.floor((Number(value).toFixed(0).length - 1) / 3) * 3;
      // Calculate the remainder
      const num = (value / ('1e' + unit)).toFixed(1);
      const unitname = units[Math.floor(unit / 3) - 1];

      return `${num} ${unitname}`;
    }

    return value.toLocaleString();
  },
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
};

export default Helpers;
