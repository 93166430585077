import * as Sentry from '@sentry/nextjs';

const isDevelopmentEnvironment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'development';

/* eslint-disable no-console */
const LoggerRaw = {
  log(...args) {
    console.log(...args);
  },
  info(...args) {
    console.info(...args);
  },
  warn(...args) {
    console.warn(...args);
  },
  error(...args) {
    Sentry.captureException(...args);
    if (isDevelopmentEnvironment) {
      console.error(...args);
    }
  }
};
/* eslint-enable no-console */

const Logger = new Proxy(LoggerRaw, {
  get: function (target, name) {
    if (!isDevelopmentEnvironment && name != 'error') {
      return () => void 0;
    }
    return target[name];
  }
});

export default Logger;
