/* eslint-disable no-useless-escape */
/* eslint-disable react/no-danger */

/**
 * Note: adding error filter for cohesion, as this request is used to determine the presence of
 * an ad blocker. So we expect this fetch to fail consistently and do not need it polluting our metrics.
 *
 * Note: adding error filters for 3rd party scripts to reduce noise
 *
 * Note: adding error filter for `/api/layout` to reduce noise (server side errors will still log to stdout)
 *
 * Note: adding error filter for aborted fetches to reduce noise - these can occur when a navigation event is interrupted by a new navigation request.
 */
export default function CloudwatchRum() {
  if (!process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_ID || !process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_POOL_ID) {
    return null;
  }
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
            'cwr',
            '${process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_ID}',
            '1.0.0',
            'us-east-1',
            'https://client.rum.us-east-1.amazonaws.com/1.16.1/cwr.js',
            {
              sessionSampleRate: 1,
              identityPoolId: "${process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_POOL_ID}",
              endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
              telemetries: [
                [
                  "errors",
                  {
                    stackTraceLength: 500,
                    ignore: (errorEvent) => {
                        return (
                          errorEvent && (
                            errorEvent?.event_details?.request?.url?.includes("cdn.cohesionapps.com") ||
                            errorEvent?.event_details?.request?.url?.includes("securepubads.g.doubleclick.net") ||
                            errorEvent?.event_details?.request?.url?.includes("pagead2.googlesyndication.com") ||
                            errorEvent?.event_details?.request?.url?.includes("mab.chartbeat.com") ||
                            errorEvent?.event_details?.request?.url?.includes("pixel.quantcount.com")
                          )
                        );
                    }
                  }
                ],
                "performance",
                [
                  "http",
                  {
                    stackTraceLength: 500,
                    addXRayTraceIdHeader: true,
                    urlsToExclude: 
                      [ /https://frontend-cdn.elsewhere.io/ ]
                  }
                ]
              ],
              allowCookies: true,
              enableXRay: true
            }
          );
        `
      }}
    />
  );
}
