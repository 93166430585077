import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@components/shared/Icon/Icon';

const Modal = (props) => {
  const { children, btnClose, disableOverlay, closeOnOverlayClick, onClose, className, isOpen } = props;

  return (
    <ReactModal
      {...props}
      isOpen={isOpen}
      overlayClassName={classnames('modal-overlay vertical-outer', {
        'modal-overlay--disabled': disableOverlay,
        [`${className}-overlay`]: className
      })}
      closeTimeoutMS={300}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={onClose}
      className={`modal__wrap ${className || ''}`}
      parentSelector={() => document.body}
    >
      {btnClose && (
        <button type="button" className="modal__close-btn" onClick={onClose}>
          <Icon icon="close" size={18} />
        </button>
      )}
      <div className="modal__body">{children}</div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  btnClose: true
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  btnClose: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  className: PropTypes.string
};

export default Modal;
