import axios from 'axios';
import qs from 'query-string';
import Router from 'next/router';

import cohesion from '@constants/cohesion';
import { storeInstance } from '@pages/_app';
import apiUrls from '@constants/api-urls';
import Helpers from '@utils/helpers';
import AuthSession from '@utils/auth-session';
import routes from '@constants/routes';

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept-Language'] = 'en';
axios.defaults.baseURL = apiUrls.apiBaseURL;

axios.interceptors.request.use(
  (config) => ({
    ...config,
    url: encodeURI(config.url)
  }),
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          if (Helpers.isClient()) {
            if (AuthSession.isTokenSet()) {
              storeInstance.userStore.logout(cohesion.sources.timeout);
            } else {
              Router.replace(routes.auth.login.index);
            }
          } else {
            throw err;
          }
          break;
        default:
          break;
      }
    }
    return Promise.reject(Helpers.isClient() ? Helpers.handleServerErrors(err) : err);
  }
);

class Api {
  static get(url, params, options = {}) {
    return axios({
      method: 'GET',
      url,
      params,
      ...options,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });
  }

  static post(url, data, options = {}) {
    return axios({
      method: 'POST',
      url,
      data,
      ...options
    });
  }

  static patch(url, data, options = {}) {
    return axios({
      method: 'PATCH',
      url,
      data,
      ...options
    });
  }

  static put(url, data, options = {}) {
    return axios({
      method: 'PUT',
      url,
      data,
      ...options
    });
  }

  static delete(url, data, options = {}) {
    return axios({
      method: 'DELETE',
      url,
      data,
      ...options
    });
  }
}

export default Api;
