/* global tagular */

import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { observer, Provider, enableStaticRendering } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ToastContainer, Slide } from 'react-toastify';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import useConstructor from '@hooks/useConstructor';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicEffect';
import IconsImportUtil from '@utils/icons';
import Helpers from '@utils/helpers';
import AuthSession from '@utils/auth-session';
import routes from '@constants/routes';
import { initRootStore } from '@store/Root';
import Loader from '@components/shared/Loader/Loader';
import NewFeatureModal from '@components/NewFeatureModal/NewFeatureModal';
import { customEvents, fetchShortProfileInterval } from '@constants/constants';
import useInterval from '@hooks/useInterval';
import { AppEventBus } from '@utils/custom-events';
import theme from '@styles/theme';
import { socialAuth } from '@constants/constants';
import 'react-toastify/dist/ReactToastify.css';
import '@styles/normalize.css';
import '@styles/main.scss';
import CloudwatchRum from '@utils/cloudwatchRum';

IconsImportUtil.import(require.context('../public/icons/', true, /\.svg$/));

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);

/*
    Redefine useLayoutEffect with useIsomorphicLayoutEffect
    for prevent useLayoutEffect calls on the server.
*/
React.useLayoutEffect = useIsomorphicLayoutEffect;

if (Helpers.isClient()) {
  enableStaticRendering(false);
} else {
  enableStaticRendering(true);
}

export let storeInstance;

AuthSession.setHeader();

const MyApp = ({ Component, pageProps, serverStoreSnapshot }) => {
  const router = useRouter();
  const store = useRef({});

  useConstructor(() => {
    storeInstance = initRootStore(serverStoreSnapshot);
    store.current = storeInstance;
    ReactModal.setAppElement('#__next');
  });

  const { userStore, demandFormStore, requestFormStore, requestSGTFormStore } = store.current;

  useInterval(
    () => {
      userStore.fetchShortInfo({ shallow: true });
      // You can listen this event in any component for same-time synced polling;
      AppEventBus.emit(customEvents.countersPollingTick);
    },
    userStore.isAuthorized() ? fetchShortProfileInterval : null
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    Helpers.appendNotificationsCounterToTitle(userStore.notificationsCount);
    demandFormStore.setDestination(null);
    tagular('pageView');
    // manually send event to cloudwatch for navigation
    if (window && window?.cwr) {
      window.cwr('recordPageView', {
        pageId: router.route,
        ...(router?.route ? { pageTags: [router.route] } : {})
      });
    }
  }, [router.route]);

  useEffect(async () => {
    if (userStore.isAuthorized()) {
      AuthSession.setHeader();
      await userStore.fetchShortInfo();
      // temporarily disabling until we figure out how to save request IDs as cookies across domains
      // requestFormStore.attachRequest();
      // requestSGTFormStore.attachRequest();
      tagular('setAuthId', userStore.data.id ? userStore.data.id.toString() : '');
    }
  }, [userStore.isAuthorized()]);

  useEffect(() => {
    // Prevent router change until user fills all required profile data fields
    const handleRouteChange = (url) => {
      const isCompleteProfileUrl = url === routes.registrationComplete;

      const abortRouteChange =
        userStore.isAuthorized() && !userStore.isLoading && !userStore.isCompleted() && isCompleteProfileUrl;

      if (abortRouteChange) {
        router.replace(routes.registrationComplete);
        throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
      }
    };
    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [userStore.isAuthorized(), userStore.isCompleted()]);

  useEffect(() => {
    Helpers.appendNotificationsCounterToTitle(userStore.notificationsCount);
  }, [userStore.notificationsCount]);

  if (userStore.isAuthorized() && userStore.isLoading) {
    return <Loader fullscreen />;
  }

  return (
    <Provider {...store.current}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={socialAuth.googleClientId}>
          <CloudwatchRum />
          <Component {...pageProps} />
          <ToastContainer transition={Slide} />
          {/* <NewFeatureModal /> */}
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Provider>
  );
};

MyApp.getInitialProps = async () => {
  const serverStore = initRootStore();
  await serverStore.regionsStore.fetchList();
  const serverStoreSnapshot = await getSnapshot(serverStore);

  return {
    serverStoreSnapshot
  };
};

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any
};

export default observer(MyApp);
