const apiUrls = {
  apiBaseURL: process.env.NEXT_PUBLIC_API_URL,
  newApiBaseURL: process.env.NEXT_PUBLIC_NEW_API_URL,
  centrifugeURL: process.env.NEXT_PUBLIC_CENTRIFUGE_URL,
  mailchimpSubscriptionFormURL: process.env.NEXT_PUBLIC_MAILCHIMP_URL,
  auth: {
    register: 'auth/register/',
    resendVerificationEmail: 'auth/register/resend-email/',
    verifyEmail: 'auth/register/verify-email/',
    facebook: 'auth/social/facebook/',
    google: 'auth/social/google/',
    login: 'auth/login/',
    logout: 'auth/logout/',
    changePassword: 'auth/password/change/',
    resetPassword: 'auth/password/reset/',
    resetPasswordConfirm: 'auth/password/reset/confirm/',
    changeEmail: 'auth/email/change/',
    newChangeEmail: 'user/email',
    changeEmailConfirm: 'auth/email/change/confirm/',
    socket: 'websocket/auth/'
  },
  files: {
    avatars: 'files/avatars/',
    coverImages: 'files/cover-images/',
    chatFiles: (chatId) => `chats/${chatId}/files`
  },
  profile: {
    index: 'user/profile',
    my: 'profiles/my/',
    short: 'user/short/',
    languages: 'profiles/my/language_choices/'
  },
  chat: {
    index: 'chats/',
    item: (id) => `chats/${id}`,
    messages: (id) => `chats/${id}/messages/`,
    newFile: (id, fileName) => `chats/${id}/files/${fileName}`,
    files: (id) => `chats/${id}/files/`,
    markAsRead: (id) => `chats/${id}/mark-as-read/`,
    welcome: (id) => `chats/${id}/welcome`
  },
  tripbuilder: {
    customtrips: {
      item: (id) => `tripbuilder/customtrips/${id}/`
    },
    customtripsdata: {
      item: (id) => `tripbuilder/customtripsdata/${id}/`
    },
    customtriptemplates: {
      item: (id) => `tripbuilder/customtriptemplates/${id}/`
    }
  },
  newMyTripsList: 'customer/trips',
  trips: {
    index: 'trips/',
    item: (id) => `trips/${id}/`,
    newItem: (id) => `customer/trips/${id}`,
    cancel: (id) => `trips/${id}/cancel`,
    requestToCancel: (id) => `trips/${id}/request-cancel/`,
    flights: {
      index: (tripId) => `trips/${tripId}/flights/`,
      item: (tripId, travellerId) => `trips/${tripId}/flights/${travellerId}/`
    },
    travelers: {
      index: (tripId) => `trips/${tripId}/travelers/`,
      item: (tripId, travellerId) => `trips/${tripId}/travelers/${travellerId}/`
    },
    attachments: {
      index: (tripId) => `trips/${tripId}/attachments/`
    },
    unreadMessages: 'trips/unread-messages'
  },
  regions: 'regions/',
  destinations: {
    index: 'destinations/',
    item: (slug) => `destinations/${slug}/`,
    demandList: 'destinations/demand-list/subscribers/'
  },
  explore: {
    index: 'inspirational/',
    item: (slug) => `inspirational/${slug}/`
  },
  tripRequests: {
    index: 'trip-requests/',
    item: (id) => `trip-requests/${id}/`,
    attach: (id) => `trip-requests/${id}/attach/me/`
  },
  payments: {
    index: 'payments/'
  },
  countries: 'countries/',
  cities: 'cities/',
  flat: {
    index: (slug) => `flatpages/${slug}/`,
    termsOfUse: 'terms',
    termsOfCancellation: 'terms-of-cancellation',
    privacyPolicy: 'privacy',
    itinerary: 'itinerary',
    fbDeleteAccount: 'account-delete',
    generalRules: 'general-rules'
  },
  contactUs: 'contact-us/',
  smallGroupTrips: {
    index: 'small-group-trips/',
    item: (id) => `small-group-trips/${id}/`,
    options: 'small-group-trips/filter-data/',
    autocomplete: (query) => `small-group-trips/autocomplete/?query=${query}`
  },
  SGTrequests: {
    index: 'small-group-trip-requests/',
    item: (id) => `small-group-trip-requests/${id}/`,
    attach: (id) => `small-group-trip-requests/${id}/attach/me/`
  },
  pageConfigs: {
    weAre: 'page-configs/we-are/',
    ourCommitments: 'page-configs/our-commitments/',
    destinations: 'page-configs/destinations/',
    homepage: 'page-configs/homepage/',
    smallGroupTrips: 'page-configs/small-group-trips/'
  }
};

export default apiUrls;
