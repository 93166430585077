//can add more statuses here as the cohesion event base grows

const cohesion = {
  sources: {
    facebook: 'facebook',
    email: 'email',
    google: 'google',
    menu: 'user menu',
    timeout: 'session timeout'
  },
  statuses: {
    failedCredentials: 'CREDENTIALS',
    success: 'AUTHENTICATION',
    failedResponse: 'CONFIRMATION'
  }
};

export default cohesion;
