class IconsImportUtil {
  static requireAll(r) {
    r.keys().forEach(r);
  }

  static import(context) {
    this.requireAll(context);
  }
}

export default IconsImportUtil;
