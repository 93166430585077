function hasDigioh() {
  return typeof window !== 'undefined' && window.DIGIOH_API;
}

export function setDigiohUser({ email, sessionId }: { email: string; sessionId: string }) {
  if (hasDigioh()) {
    window.DIGIOH_API.setDataLayerValue('email', email);
    window.DIGIOH_API.setDataLayerValue('sessionId', sessionId);
  }
}

export function setDigiohTrip({ tripId, tripCreated }: { tripId: string; tripCreated?: string }) {
  if (hasDigioh()) {
    window.DIGIOH_API.setDataLayerValue('tripId', tripId);
    window.DIGIOH_API.setDataLayerValue('tripCreated', tripCreated);
  }
}

export function clearDigioh() {
  if (hasDigioh()) {
    window.DIGIOH_API.clearDataLayer();
  }
}
