import { flow, types, getParent } from 'mobx-state-tree';
import Router from 'next/router';

import {
  completedRequestCookieKey,
  requestFormSteps,
  requestFormStepsMap,
  storeInitialState
} from '@constants/request-form';
import Logger from '@utils/logger';
import Api from '@utils/api';
import apiUrls from '@constants/api-urls';
import Cookies from '@utils/cookies';
import ga from '@utils/ga';

const changeRouterQuery = (requestId, step) => {
  Router.push({
    pathname: Router.asPath.replace(/\?.*/g, ''),
    query: {
      id: requestId,
      step
    }
  });
};

export const RequestFormStore = types
  .model('RequestFormStore', {
    data: types.frozen({}),
    dataIsLoading: false,
    currentStep: types.enumeration(requestFormStepsMap)
  })
  .views((self) => ({
    get requestId() {
      return self.data.id;
    },
    cookiesRequestFormId() {
      return Cookies.get(completedRequestCookieKey);
    },
    cookiesRequestFormIdReset() {
      return Cookies.remove(completedRequestCookieKey);
    },
    currentStepIndex() {
      return requestFormStepsMap.findIndex((step) => step === self.currentStep);
    }
  }))
  .actions((self) => ({
    startQuestionnaire() {
      self.currentStep = requestFormSteps.destination;
      changeRouterQuery(undefined, 1);
    },
    setStep(step) {
      self.currentStep = step;
    },
    prevStep() {
      const prevIndexStep = self.currentStepIndex() - 1;
      self.currentStep = requestFormStepsMap[prevIndexStep];
      changeRouterQuery(self.requestId, prevIndexStep);
    },
    nextStep() {
      const nextIndexStep = self.currentStepIndex() + 1;
      self.currentStep = requestFormStepsMap[nextIndexStep];
      changeRouterQuery(self.requestId, nextIndexStep);
    },
    reset() {
      Object.keys(storeInitialState).forEach((key) => (self[key] = storeInitialState[key]));
    },
    fetchRequestData: flow(function* (requestFormId) {
      try {
        self.dataIsLoading = true;
        const response = yield Api.get(apiUrls.tripRequests.item(requestFormId));
        self.data = { ...self.data, ...response.data };
        self.dataIsLoading = false;
      } catch (e) {
        Logger.error(e);
      }
    }),
    createRequest: flow(function* (requestData) {
      const response = yield Api.post(apiUrls.tripRequests.index, requestData);
      self.data = { ...self.data, ...response.data };
      Cookies.set(completedRequestCookieKey, response.data.id);
      self.nextStep();
    }),
    updateRequest: flow(function* (requestData) {
      const response = yield Api.put(apiUrls.tripRequests.item(self.requestId), requestData);
      self.data = { ...self.data, ...response.data };
      if (self.currentStepIndex() < requestFormStepsMap.length - 1) {
        self.nextStep();
      }
    }),
    attachRequest: flow(function* () {
      const rootStore = getParent(self);
      const displayTripsPopup = rootStore?.userStore?.data?.display_trips_popup;

      const completedRequestId = self.cookiesRequestFormId();
      if (!completedRequestId) {
        return;
      }
      try {
        yield Api.post(apiUrls.tripRequests.attach(completedRequestId));
      } catch (e) {
        Logger.error(e);
      } finally {
        if (displayTripsPopup) {
          ga.sendRegisteredUserNewTripLogin();
        } else {
          ga.sendRegisteredUserNewTrip();
        }
        self.cookiesRequestFormIdReset();
      }
    })
  }));
