const requestFormSteps = {
  welcome: 'welcome',
  destination: 'destination',
  dates: 'dates',
  accommodation: 'accommodation',
  priorities: 'priorities',
  extraordinary: 'extraordinary',
  budget: 'budget',
  why: 'why'
};

const requestFormStepsMap = [
  requestFormSteps.destination,
  requestFormSteps.dates,
  requestFormSteps.accommodation,
  requestFormSteps.priorities,
  requestFormSteps.why,
  requestFormSteps.budget
];

const requestSGTFormSteps = {
  welcome: 'welcome',
  trip: 'trip',
  extra: 'extra',
  passport: 'passport'
};

const requestSGTFormStepsMap = [
  requestSGTFormSteps.welcome,
  requestSGTFormSteps.trip,
  requestSGTFormSteps.extra,
  requestSGTFormSteps.passport
];

const accommodationsEnum = {
  simple: 'simple',
  comfy: 'comfy',
  luxury: 'luxury'
};

const prioritiesEnum = {
  classic: 'classic',
  local_culture: 'local_culture',
  urban: 'urban',
  wild_and_untouched: 'wild_and_untouched',
  away_form_beaten_path: 'away_form_beaten_path',
  feed_me: 'feed_me',
  healthy: 'healthy',
  smarter: 'smarter'
};

const extraordinariesEnum = {
  rustic: 'rustic',
  luxury: 'luxury',
  either: 'either'
};

const budgetsEnum = {
  maximal_budget: 'maximal_budget',
  can_be_flexible: 'can_be_flexible',
  not_worried: 'not_worried'
};

const specialsEnum = {
  boutique: 'boutique',
  eco_lodges: 'eco_lodges',
  hustle_and_bustle: 'hustle_and_bustle',
  mix: 'mix'
};

const storeInitialState = {
  data: {},
  currentStep: requestFormSteps.destination
};

const storeInitialSGTState = {
  data: {},
  currentStep: requestSGTFormSteps.welcome
};

const completedRequestCookieKey = 'elsewhere-request';
const completedRequestSGTCookieKey = 'elsewhere-request-sgt';

export {
  requestFormSteps,
  requestFormStepsMap,
  requestSGTFormSteps,
  requestSGTFormStepsMap,
  accommodationsEnum,
  prioritiesEnum,
  extraordinariesEnum,
  budgetsEnum,
  specialsEnum,
  storeInitialState,
  storeInitialSGTState,
  completedRequestCookieKey,
  completedRequestSGTCookieKey
};
