import { types } from 'mobx-state-tree';

export const UserModel = types.model('UserModel', {
  id: types.maybeNull(types.integer),
  email: types.maybeNull(types.string),
  avatar: types.frozen({}),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  is_profile_completed: false,
  display_trips_popup: false,
  notificationsCount: 0
});
