import { flow, types } from 'mobx-state-tree';

import Logger from '@utils/logger';
import Api from '@utils/api';
import { setDigiohTrip } from '@utils/digioh';
import apiUrls from '@constants/api-urls';
import { tripStatuses } from '@constants/constants';

export const TripStore = types
  .model('TripStore', {
    data: types.frozen({}),
    isLoading: false
  })
  .views((self) => ({
    isStartedOrCompleted() {
      return [tripStatuses.on_trip, tripStatuses.completed].includes(self.data.status);
    }
  }))
  .actions((self) => ({
    fetchDetails: flow(function* ({ tripId, shallow }) {
      try {
        if (!shallow) {
          self.isLoading = true;
        }
        const response = yield Api.get(apiUrls.trips.item(tripId));
        self.data = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.isLoading = false;
        setDigiohTrip({ tripId: self.data.trip_id, tripCreated: self.data.created });
      }
    }),
    updateDetails: flow(function* ({ tripId, values }) {
      try {
        const response = yield Api.patch(apiUrls.trips.item(tripId), values);
        self.data = response.data;
      } catch (e) {
        Logger.error(e);
      }
    }),
    reset() {
      self.data = {};
      self.isLoading = false;
    }
  }));
