import { types, flow } from 'mobx-state-tree';

import Logger from '@utils/logger';
import Api from '@utils/api';
import Helpers from '@utils/helpers';
import apiUrls from '@constants/api-urls';

export const SmallGroupTripsStore = types
  .model('SmallGroupTripsStore', {
    SGTList: types.frozen([]),
    SGTListIsLoading: false,
    SGTFiltersIsLoading: false,
    isLoading: false,
    options: types.frozen([]),
    trip: types.frozen({}),
    tripIsLoading: false
  })
  // .views((self) => ({}))
  .actions((self) => ({
    tripReset() {
      self.trip = null;
    },
    fetchSGTList: flow(function* () {
      try {
        self.SGTListIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.index);
        // eslint-disable-next-line no-console
        console.log(response);
        self.SGTList = response.data.results;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.SGTListIsLoading = false;
      }
    }),
    fetchTripsAutocomplete: flow(function* (input) {
      try {
        self.tripsListIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.autocomplete(input));
        // eslint-disable-next-line no-console
        self.tripsList = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.tripsListIsLoading = false;
      }
    }),
    fetchTripById: flow(function* (tripId) {
      try {
        self.tripIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.item(tripId));
        // eslint-disable-next-line no-console
        self.trip = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.tripIsLoading = false;
      }
    }),
    fetchSGTFilters: flow(function* () {
      try {
        self.SGTFiltersIsLoading = true;
        const response = yield Api.get(apiUrls.smallGroupTrips.options);
        self.options = {
          tags: Helpers.formatOptions(response.data.themes, true),
          seasons: Helpers.formatOptions(response.data.seasons, true),
          regions: Helpers.formatOptions(response.data.regions, true)
        };
      } catch (e) {
        Logger.error(e);
      } finally {
        self.SGTFiltersIsLoading = false;
      }
    })
  }));
