import Helpers from './helpers';

const gtagIsAvailable = Helpers.isClient() && 'gtag' in window;

const GaRaw = {
  sendRegisteredUserNewTrip() {
    window.gtag('event', 'RegisteredUserNewTrip');
  },
  sendRegisteredUserNewTripLogin() {
    window.gtag('event', 'RegisteredUserNewTripLogin');
  },
  sendRequestFormStep0() {
    window.gtag('event', 'RequestFormStep0');
  },
  sendRequestFormStep1() {
    window.gtag('event', 'RequestFormStep1');
  },
  sendRequestFormStep2() {
    window.gtag('event', 'RequestFormStep2');
  },
  sendRequestFormStep3() {
    window.gtag('event', 'RequestFormStep3');
  },
  sendRequestFormStep4() {
    window.gtag('event', 'RequestFormStep4');
  },
  sendRequestFormStep5() {
    window.gtag('event', 'RequestFormStep5');
  },
  sendRequestFormStep6() {
    window.gtag('event', 'RequestFormStep6');
  },
  sendRequestFormCompleted() {
    window.gtag('event', 'RequestFormCompleted');
  }
};

const GaUtil = new Proxy(GaRaw, {
  get: function (target, name) {
    if (!gtagIsAvailable) {
      return () => void 0;
    }
    return target[name];
  }
});

export default GaUtil;
