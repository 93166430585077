import axios from 'axios';
import Router from 'next/router';

import cohesion from '@constants/cohesion';
import { storeInstance } from '@pages/_app';
import apiUrls from '@constants/api-urls';
import Helpers from '@utils/helpers';
import AuthSession from '@utils/auth-session';
import routes from '@constants/routes';

const headers = {
  'Accept-Language': 'en',
  'Content-Type': 'application/json'
};

const NewApi = axios.create({ baseURL: apiUrls.newApiBaseURL, headers });

NewApi.interceptors.request.use(
  (config) => ({
    ...config,
    url: encodeURI(config.url)
  }),
  (error) => Promise.reject(error)
);

NewApi.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          if (Helpers.isClient()) {
            if (AuthSession.isTokenSet()) {
              storeInstance.userStore.logout(cohesion.sources.timeout);
            } else {
              Router.replace(routes.auth.login.index);
            }
          } else {
            throw err;
          }
          break;
        default:
          break;
      }
    }
    return Promise.reject(Helpers.isClient() ? Helpers.handleServerErrors(err) : err);
  }
);

export default NewApi;
