import { types } from 'mobx-state-tree';

import modals from '@constants/modals';

export const ModalsStore = types
  .model('ModalsStore', {
    [modals.logout]: false
  })
  .actions((self) => ({
    toggleModal(modal) {
      self[modal] = !self[modal];
    },
    closeAllModals() {
      Object.keys(self).forEach((key) => {
        self[key] = false;
      });
    }
  }));
