import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    text2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    text2?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    text2: true;
  }
}

export const elsewhere = {
  sizing: {
    1: '0.06rem',
    4: '0.25rem',
    8: '0.5rem',
    12: '0.75rem',
    14: '0.87rem',
    16: '1rem',
    18: '1.12rem',
    20: '1.25rem',
    22: '1.37rem',
    24: '1.5rem',
    27: '1.68rem',
    28: '1.75rem',
    30: '1.87rem',
    32: '2rem',
    34: '2.12rem',
    36: '2.25rem',
    38: '2.37rem',
    40: '2.5rem',
    44: '2.75rem',
    42: '2.62rem',
    46: '2.87rem',
    48: '3rem',
    50: '3.12rem',
    54: '3.37rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem'
  },
  color: {
    'gray-1': '#F9F9FA',
    'gray-2': '#F3F1F6',
    'gray-3': '#EBE8F1',
    'gray-4': '#DCD8E7',
    'gray-5': '#C0BACC',
    'gray-6': '#8A8597',
    'gray-7': '#5C5766',
    'gray-8': '#342F41',
    'gray-9': '#1C1A21',
    'gray-10': '#DCD8F1',
    'gray-11': '#F7EEFF',
    'gray-12': '#8F8B9C',
    white: '#FFFFFF',
    'white-transparent-4': 'rgba(255,255,255, 0.4)',
    'white-transparent-6': 'rgba(255,255,255, 0.6)',
    'white-transparent-8': 'rgba(255,255,255, 0.8)',
    'elsewhere-dawn': 'linear-gradient(36deg, rgba(69, 63, 248, 1) 0%, rgba(162, 0, 237, 1) 100%)',
    'elsewhere-dawn-hover': 'linear-gradient(36deg, rgba(45, 61, 209, 1) 0%, rgba(162, 0, 237, 1) 100%)',
    'elsewhere-blue': '#1C1A21',
    'dark-blue': '#0054C6',
    'elsewhere-purple': '#A200ED',
    'dark-purple': '#7500AC',
    'deep-purple': '#751EF2',
    mauve: '#6C5AC6',
    'blue-super-light': '#F4F2FF',
    'blue-light': '#D8D9FF',
    green: '#56B68C',
    'green-super-light': '#F2FFF3',
    red: '#E03A86',
    'red-super-light': '#FFF2F7'
  },
  fontFamily: `"Geomanist webfont", sans-serif`,
  fontWeight: {
    light: '300',
    regular: '500',
    book: '600',
    medium: '700'
  },
  shadow: {
    subtle: '0px 1px 2px #5c576626',
    tiny: '0px 1px 4px #5c576626',
    small: '0px 2px 8px #5c576626',
    large: '0px 7px 17px #5c576626',
    button_hover: '0px 3px 8px #6c5ac640',
    slider_thumb_hover: '0px 0px 8px #6c5ac640'
  },
  textShadow: {
    small: '0 1px 2px #342f414d;'
  },
  borderRadius: {
    10: '10px',
    12: '12px',
    16: '16px',
    24: '24px',
    32: '32px',
    48: '48px',
    '50%': '50%'
  },
  mapSize: {
    xs: 112,
    md: 132,
    xl: 162
  },
  breakpoints: {
    desktop: {} //This value is set below
  }
};

const theme = createTheme({
  typography: {
    fontFamily: elsewhere.fontFamily,
    text2: {
      color: elsewhere.color['gray-7']
    }
  },
  palette: {
    primary: {
      main: elsewhere.color['elsewhere-blue'],
      dark: elsewhere.color['dark-blue'],
      contrastText: '#fff'
    },
    secondary: {
      main: elsewhere.color['elsewhere-purple'],
      dark: elsewhere.color['dark-purple'],
      contrastText: '#fff'
    },
    text: {
      primary: elsewhere.color['gray-8'],
      secondary: elsewhere.color['gray-6']
    },
    error: {
      main: elsewhere.color.red
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          [theme.breakpoints.up('lg')]: {
            maxWidth: '1120px',
            paddingRight: '40px',
            paddingLeft: '40px'
          },
          [theme.breakpoints.up('md')]: {
            paddingRight: '40px!important',
            paddingLeft: '40px!important'
          },
          [theme.breakpoints.up('xs')]: {
            paddingRight: '24px',
            paddingLeft: '24px'
          }
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          boxShadow: elsewhere.shadow.small,
          border: '1px solid #5c576626',
          borderRadius: '10px',
          transition: 'all ease-out .2s'
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '.MuiMenuItem-root': {
            minHeight: '38px',
            minWidth: '200px',
            '&:hover': {
              backgroundColor: elsewhere.color['gray-1']
            },
            '.MuiListItemText-root': {
              fontWeight: elsewhere.fontWeight.medium,
              '.MuiTypography-root': {
                fontSize: elsewhere.sizing[14]
              }
            }
          }
        })
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: 56,
          height: 32,
          padding: 0,
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
          '&:hover': {
            '& .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'light' ? elsewhere.color['gray-5'] : '#39393D'
            }
          }
        }),
        switchBase: ({ ownerState, theme }) => ({
          margin: 0,
          paddingRight: 0,
          paddingLeft: '3px',
          paddingTop: '3px',
          paddingBottom: 0,
          '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : elsewhere.color.green,
              opacity: 1,
              border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5
            }
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
          }
        }),
        thumb: ({ ownerState, theme }) => ({
          boxSizing: 'border-box',
          width: 26,
          height: 26
        }),
        track: ({ ownerState, theme }) => ({
          borderRadius: 20,
          backgroundColor: theme.palette.mode === 'light' ? elsewhere.color['gray-4'] : '#39393D',
          opacity: 1
        })
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined2' },
          style: {}
        },
        {
          props: { variant: 'plain' },
          style: {}
        }
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: elsewhere.borderRadius[24],
          fontSize: elsewhere.sizing[16],
          lineHeight: elsewhere.sizing[24],
          fontWeight: elsewhere.fontWeight.medium,
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            boxShadow: elsewhere.shadow.subtle
          },
          ...(ownerState.variant === 'text' && {
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: elsewhere.color['blue-super-light'],
              textDecoration: 'underline'
            }
          }),
          ...(ownerState.variant === 'contained' && {
            backgroundImage: elsewhere.color['elsewhere-dawn'],
            '&:hover': {
              backgroundImage: elsewhere.color['elsewhere-dawn-hover'],
              boxShadow: elsewhere.shadow.button_hover
            }
          }),
          ...((ownerState.variant === 'outlined' || (ownerState.variant === 'outlined2' && ownerState.disabled)) && {
            backgroundColor: 'transparent',
            borderColor: elsewhere.color['gray-5'],
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: elsewhere.color['gray-7'],
              boxShadow: elsewhere.shadow.small
            }
          }),
          ...(ownerState.variant === 'outlined2' &&
            !ownerState.disabled && {
              position: 'relative',
              border: '2px solid transparent',
              backgroundClip: 'padding-box',
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
                boxShadow: elsewhere.shadow.button_hover
              },
              '&:after': {
                position: 'absolute',
                top: -2,
                left: -2,
                right: -2,
                bottom: -2,
                backgroundImage: `${elsewhere.color['elsewhere-dawn-hover']}`,
                content: '""',
                zIndex: -1,
                borderRadius: '26px'
              }
            }),
          ...(ownerState.disabled === true && {
            backgroundImage: 'none',
            border: 'none!important',
            BorderColor: 'transparent',
            background: '#F3F1F6!important',
            color: '#C0BACC!important'
          }),
          ...(ownerState.disabled === true &&
            ownerState.variant === 'text' && {
              background: 'transparent',
              textDecoration: 'none'
            }),
          ...(ownerState.size === 'large' && {
            height: '48px',
            minWidth: '210px',
            borderRadius: '26px',
            paddingLeft: elsewhere.sizing['32'],
            paddingRight: elsewhere.sizing['32']
          }),
          ...(ownerState.size === 'medium' && {
            height: '38px',
            fontSize: '14',
            minWidth: '100px',
            paddingLeft: elsewhere.sizing['16'],
            paddingRight: elsewhere.sizing['16'],
            '.MuiButton-startIcon': {
              marginRight: '6px'
            }
          }),
          ...(ownerState.size === 'small' && {
            height: '32px',
            fontSize: '14',
            paddingLeft: elsewhere.sizing['16'],
            paddingRight: elsewhere.sizing['16']
          }),
          ...(ownerState.variant === 'plain' && {
            background: 'transparent',
            boxShadow: 'none',
            textTransform: 'none',
            border: 'none',
            minWidth: 'auto',
            padding: '0',
            height: 'auto',
            borderRadius: '0',
            lineHeight: '1'
          }),
          ...(ownerState.variant === 'plain' &&
            (ownerState.disabled === true || ownerState.hover === true) && {
              background: 'transparent',
              boxShadow: 'none',
              textTransform: 'none'
            })
        })
      }
    }
  },
  elsewhere
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlined2: true;
    plain: true;
  }
}

const desktopBreakpoint = theme.breakpoints.up('sm');
elsewhere.breakpoints.desktop = desktopBreakpoint;

theme.typography.h1 = {
  fontSize: '2.25rem',
  lineHeight: '2.625rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.75rem',
    lineHeight: '3.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '3.5rem',
    lineHeight: '4rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.h2 = {
  fontSize: '2rem',
  lineHeight: '2.375rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.375rem',
    lineHeight: '2.875rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.87rem',
    lineHeight: '3.37rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.h3 = {
  fontSize: '1.75rem',
  lineHeight: '2.125rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.875rem',
    lineHeight: '2.375rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.37rem',
    lineHeight: '2.87rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.h4 = {
  fontSize: '1.5rem',
  lineHeight: '2rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.87rem',
    lineHeight: '2.37rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.h5 = {
  fontSize: '1.25rem',
  lineHeight: '1.625rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.375rem',
    lineHeight: '1.875rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.h6 = {
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.125rem',
    lineHeight: '1.625rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem'
  },
  fontWeight: elsewhere.fontWeight.medium
};

theme.typography.body1 = {
  fontSize: elsewhere.sizing['16'],
  lineHeight: elsewhere.sizing['24']
};

theme.typography.body2 = {
  fontSize: '0.87rem',
  lineHeight: '1.37rem',
  fontWeight: elsewhere.fontWeight.regular
};

theme.typography.caption = {
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  fontWeight: elsewhere.fontWeight.regular
};

theme.typography.subtitle1 = {
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  fontWeight: elsewhere.fontWeight.regular
};

theme.typography.text2 = {
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.125rem',
    lineHeight: '1.625rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem'
  },
  fontWeight: elsewhere.fontWeight.regular
};

export default theme;
