import { flow, types } from 'mobx-state-tree';
import Router from 'next/router';

import { UserModel } from '../models/User';

import apiUrls from '@constants/api-urls';
import cohesion from '@constants/cohesion';
import routes from '@constants/routes';
import Api from '@utils/api';
import AuthSession from '@utils/auth-session';
import Helpers from '@utils/helpers';
import Logger from '@utils/logger';
import NewApi from '@utils/newapi';
import { sendAuthEvent } from '@utils/sendEvent';
import { setDigiohUser, clearDigioh } from '@utils/digioh';

let newMessageSound;

if (Helpers.isClient()) {
  newMessageSound = new Audio('/sounds/open_bottle.mp3');
}

export const UserStore = types
  .model('UserStore', {
    data: types.optional(UserModel, {}),
    isLoading: true, // set isLoading to true by default for avoid double rendering on flag change
    isCohesionLoading: true
  })
  .views((self) => ({
    get notificationsCount() {
      return self.data.notificationsCount;
    },
    get fullName() {
      return `${self.data.firstName} ${self.data.lastName}`;
    },
    isAuthorized() {
      return AuthSession.isTokenSet();
    },
    isCompleted() {
      return !!(self.data.firstName && self.data.lastName);
    }
  }))
  .actions((self) => ({
    updateUser(data) {
      self.data = data || {};
    },
    setAvatar(avatar) {
      self.data.avatar = avatar;
    },
    setLoading(flag) {
      self.isLoading = flag;
    },
    setCohesionLoading(flag) {
      self.isCohesionLoading = flag;
    },
    setCohesionData(data) {
      self.data.cohesionData = data;
    },
    setIsCompleted(flag) {
      self.data.isProfileCompleted = flag;
    },
    async login(userData) {
      //Capture cohesion data on userstore at login, since we can't use our cohesion hook
      const timer = setTimeout(() => self.setCohesionLoading(false), 3000);
      if (window && window.cohesion) {
        window.cohesion('ready', () => {
          clearTimeout(timer);
          self.setCohesionLoading(false);

          const { anonymousId, sessionId, instanceId } = window._Cohesion;

          self.setCohesionData({ anonymousId, sessionId, instanceId });
          setDigiohUser({ sessionId, email: userData.user.email });
        });
      }

      const { query, asPath } = Router;
      await Router.replace(
        {
          pathname: asPath.replace(/\?.*/g, ''),
          query: {
            redirect_to: decodeURIComponent(query.redirect_to) || routes.trips.my
          }
        },
        undefined,
        { shallow: true }
      );
      self.updateUser(userData.user);
      AuthSession.set(userData.key);

      Router.push(decodeURIComponent(query.redirect_to) || routes.trips.my);
    },
    logout: flow(function* (source) {
      let error;
      try {
        yield Api.post(apiUrls.auth.logout);
      } catch (e) {
        error = e;
        Logger.error(e);
      } finally {
        //we send logout events from the userstore so we have access to errors
        if (!self.isCohesionLoading) {
          //check if cohesion is ready before sending logout event
          sendAuthEvent(
            {
              user: { id: self.data.id },
              anonymousId: self.data.cohesionData.anonymousId,
              sessionId: self.data.cohesionData.sessionId,
              instanceId: self.data.cohesionData.instanceId,
              autofillUsed: false,
              source: source,
              status: error ? cohesion.statuses.failedResponse : cohesion.statuses.success
            },
            'logout'
          );
        }

        self.updateUser();
        self.setCohesionData();
        AuthSession.remove();
        clearDigioh();
        Router.replace(routes.auth.login.index);
      }
    }),
    fetchShortInfo: flow(function* ({ shallow } = {}) {
      try {
        if (!shallow) {
          self.isLoading = true;
        }
        const response = yield NewApi.get(apiUrls.profile.short);

        //Play sound if notifications counter was changed
        const isNotInitialQuery = self.data.id;
        if (isNotInitialQuery && self.data.notificationsCount < response.data.notificationsCount) {
          newMessageSound.play();
        }

        self.updateUser({ ...response.data, id: response.data.userId });
        //If user need to fill additional field redirect user to reg complete page
        const isCompleted = !!(response.data.firstName && response.data.lastName);
        if (!isCompleted) {
          Router.replace(routes.registrationComplete);
        }
      } catch (e) {
        Logger.error(e);
      } finally {
        self.isLoading = false;
      }
    })
  }));
