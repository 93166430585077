import { useRef } from 'react';

const useConstructor = (callbackFn = () => {}) => {
  const hasBeenCalled = useRef(false);

  if (hasBeenCalled.current) {
    return;
  }
  callbackFn();

  hasBeenCalled.current = true;
};

export default useConstructor;
