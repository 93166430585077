import { flow, types } from 'mobx-state-tree';
import Router from 'next/router';

import Api from '@utils/api';
import apiUrls from '@constants/api-urls';
import routes from '@constants/routes';

export const DemandFormStore = types
  .model('DemandFormStore', {
    destination: types.maybeNull(types.number)
  })
  .actions((self) => ({
    setDestination(destination) {
      self.destination = destination;
    },
    submit: flow(function* (values, actions) {
      try {
        actions.setSubmitting(true);
        yield Api.post(apiUrls.destinations.demandList, values);
        actions.resetForm();
        self.destination = null;
      } catch (e) {
        actions.setErrors(e);
      } finally {
        actions.setSubmitting(false);
      }
    })
  }));
