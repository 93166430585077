import { types, flow } from 'mobx-state-tree';

import Logger from '@utils/logger';
import Api from '@utils/api';
import apiUrls from '@constants/api-urls';
import { destinationStatuses } from '@constants/constants';

export const RegionsStore = types
  .model('RegionsStore', {
    data: types.frozen([]),
    countriesList: types.frozen([]),
    countriesListIsLoading: false,
    isLoading: false,
    destinationsMap: types.frozen({}),
    destinationDetailsIsLoading: false
  })
  .views((self) => ({
    get usaObj() {
      const usaObj = self.countriesList.find((country) => country.name === 'United States');
      return usaObj || '';
    },
    get selectOptions() {
      return self.data.reduce((acc, curr) => {
        const currentRegionOptions = curr.destinations.map((destination) => ({
          label: destination.name,
          value: destination.id,
          status: destination.status,
          slug: destination.slug
        }));
        return [...acc, ...currentRegionOptions];
      }, []);
    },
    get demandDestinationsIds() {
      return self.selectOptions.reduce((acc, curr) => {
        if (curr.status === destinationStatuses.demand_list) {
          acc.add(curr.value);
        }
        return acc;
      }, new Set());
    },
    groupedSelectOptions(key) {
      return self.data.map((region) => ({
        label: region.name,
        options: region.destinations.map((destination) => ({
          label: destination.name,
          value: destination[key]
        }))
      }));
    }
  }))
  .actions((self) => ({
    fetchList: flow(function* () {
      try {
        self.isLoading = true;
        const response = yield Api.get(apiUrls.regions);
        self.data = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchDestinationDetails: flow(function* (slug) {
      try {
        self.destinationDetailsIsLoading = true;
        const response = yield Api.get(apiUrls.destinations.item(slug));
        self.destinationsMap = {
          ...self.destinationsMap,
          [slug]: response.data
        };
      } catch (e) {
        Logger.error(e);
      } finally {
        self.destinationDetailsIsLoading = false;
      }
    }),
    fetchCountriesList: flow(function* () {
      try {
        self.countriesListIsLoading = true;
        const response = yield Api.get(apiUrls.countries);
        self.countriesList = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.countriesListIsLoading = false;
      }
    })
  }));
