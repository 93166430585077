import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Icon.module.scss';

const Icon = ({ icon, className, size, style, withTransition, ...props }: any) => {
  const componentInlineStyles = style ? Object.assign({}, style) : {};

  if (size) {
    componentInlineStyles.width = componentInlineStyles.height = size;
  }

  return (
    <svg
      className={classnames(styles.icon, className, {
        [styles.transition]: withTransition
      })}
      style={componentInlineStyles}
      aria-hidden="true"
      {...props}
    >
      <use href={`#${icon}`} />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  withTransition: PropTypes.bool
};

export default Icon;
