/* eslint-disable */
import Modal from '@components/shared/Modal/Modal';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import theme from '@styles/theme';
import { inject, observer } from 'mobx-react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import styles from './NewFeatureModal.module.scss';

const NewFeatureModal = ({ userStore }) => {
  const [open, setOpen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  useEffect(() => {
    const path = window.location.pathname;
    const modalViewed = localStorage.getItem(`${userStore.data.email}${path}`);

    if (modalViewed !== 'true' && userStore.isAuthorized && (path === '/my-trips' || path === '/inbox')) {
      setOpen(true);
      localStorage.setItem(`${userStore.data.email}${path}`, 'true');
    }
  }, [open, setOpen, userStore]);

  const closeModal = () => setOpen(!open);
  return (
    <Modal className={styles['new-feature-modal']} isOpen={open} onClose={closeModal}>
      <Box className={styles['new-feature-modal__content']}>
        {/* <Image
          src="/img/makeover-img-full.jpg"
          style={{ overflow: isSmall ? 'hidden' : 'visible' }}
          imgStyle={{
            position: isSmall ? 'relative' : 'absolute',
            left: isSmall ? '' : '-56px',
            top: isSmall ? '' : '8px',
            padding: '0px',
            width: isSmall ? '220px' : ''
          }}
          layout={!isSmall ? 'fixed' : 'responsive'}
          width={332}
          height={238}
        /> */}
        <Box
          sx={{
            backgroundImage: "url('/img/makeover-img-full.jpg')",
            backgroundSize: { xs: '85%', md: 'contain' },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            width: { xs: '100%', md: '332px' },
            height: { xs: '208px', md: '238px' },
            position: 'relative',
            left: { xs: '0', md: '-44px' },
            top: '10px'
          }}
        />
        <Box sx={{ width: isSmall ? 'auto' : 400 }}>
          <Typography
            variant="h5"
            align="left"
            marginTop={theme.elsewhere.sizing[8]}
            marginBottom={theme.elsewhere.sizing[24]}
          >
            Elsewhere got a makeover, and we want to know what you think
          </Typography>
          <Typography variant="body1" align="left" fontWeight="medium" marginBottom={theme.elsewhere.sizing[16]}>
            <strong>Spoiler Alert:</strong> We have been self improving our asses off, but we aren’t perfect yet.
          </Typography>
          <Typography variant="body1" align="left" color={theme.elsewhere.color['gray-6']} fontWeight={500}>
            If you notice any bugs or issues with our new customer area, we'd love to hear from you. Email our
            co-founder, Craig, if you spot anything funky:{' '}
            <Link href="mailto:craig@elsewhere.io" color={theme.elsewhere.color['gray-8']}>
              craig@elsewhere.io.
            </Link>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default inject('userStore')(observer(NewFeatureModal));
