import { types, applySnapshot } from 'mobx-state-tree';
import getConfig from 'next/config';

import { ModalsStore } from './Modals';
import { UserStore } from './User';
import { RegionsStore } from './Regions';
import { RequestFormStore } from './RequestForm';
import { TripStore } from './Trip';
import { CustomTripStore } from './CustomTrip';
import { InspirationalDestinationStore } from './InspirationalDestination';
import { DemandFormStore } from './DemandForm';
import { SmallGroupTripsStore } from './SmallGroupTrips';
import { RequestSGTFormStore } from './RequestSGTForm';

import { storeInitialState as requestFormStoreInitialState, storeInitialSGTState } from '@constants/request-form';
import Helpers from '@utils/helpers';

let store;

const RootStore = types.model('RootStore', {
  userStore: types.optional(UserStore, {}),
  modalsStore: types.optional(ModalsStore, {}),
  regionsStore: types.optional(RegionsStore, {}),
  requestFormStore: types.optional(RequestFormStore, requestFormStoreInitialState),
  tripStore: types.optional(TripStore, {}),
  customTripStore: types.optional(CustomTripStore, {}),
  inspirationalDestinationStore: types.optional(InspirationalDestinationStore, {}),
  demandFormStore: types.optional(DemandFormStore, {}),
  smallGroupTripsStore: types.optional(SmallGroupTripsStore, {}),
  requestSGTFormStore: types.optional(RequestSGTFormStore, storeInitialSGTState)
});

export function initRootStore(snapshot = null) {
  const isServer = !Helpers.isClient();
  const { publicRuntimeConfig } = getConfig();
  const { nodeEnv } = publicRuntimeConfig;

  const isDevelopmentEnvironment = nodeEnv === 'development';

  if (isServer || !store) {
    store = RootStore.create({});
  }

  if (snapshot) {
    applySnapshot(store, snapshot);
  }

  return store;
}
