import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Loader = ({ fullscreen }) => (
  <div className={classnames('loader', { 'loader--fullscreen': fullscreen })}>
    <div className="loader-circle"></div>
  </div>
);

Loader.propTypes = {
  fullscreen: PropTypes.bool
};

export default Loader;
