import { createHash } from 'crypto';

import { v4 as uuidv4 } from 'uuid';

import cohesion from '@constants/cohesion';
import { appDomain, makeSourceKey } from '@constants/constants';
//would like to add more constants from the @constants/cohesion file

function tagularSet() {
  return 'tagular' in window;
}

function hashEmail(email) {
  const hash = createHash('sha256');
  return email ? hash.update(email).digest('hex') : '';
}

export function sendEvent({ event, type, nameSpace, payload, eventCategory, webContextNeeded }) {
  if (tagularSet()) {
    window.tagular(
      'beam',
      `${event}`,
      {
        '@type': `${nameSpace}.${eventCategory}.${type}`,
        ...payload
      },
      false,
      webContextNeeded
    );
  }
}

export function emailSubscribed(payload) {
  sendEvent({
    event: 'EmailSubscribed',
    nameSpace: 'core',
    eventCategory: 'EmailSubscribed',
    type: 'v1',
    webContextNeeded: false,
    payload
  });
}

function accountLogIn(payload) {
  sendEvent({
    event: 'AccountLogIn',
    nameSpace: 'core',
    eventCategory: 'AccountLogin',
    type: 'v1',
    webContextNeeded: true,
    payload
  });
}

function accountLogOut(payload) {
  sendEvent({
    event: 'AccountLogout',
    nameSpace: 'core',
    eventCategory: 'AccountLogout',
    type: 'v1',
    webContextNeeded: true,
    payload
  });
}

function accountCreated(payload) {
  sendEvent({
    event: 'AccountCreated',
    nameSpace: 'core',
    eventCategory: 'AccountCreated',
    type: 'v1',
    webContextNeeded: true,
    payload
  });
}

function formSubmitted(payload) {
  sendEvent({
    event: 'FormSubmitted',
    nameSpace: 'redventures',
    eventCategory: 'usertracking.v3',
    type: 'FormSubmitted',
    webContextNeeded: true,
    payload
  });
}

function productClicked(payload) {
  sendEvent({
    event: 'ProductClicked',
    nameSpace: 'redventures',
    eventCategory: 'ecommerce.v1',
    type: 'ProductClicked',
    webContextNeeded: true,
    payload
  });
}

export function elementClicked(payload) {
  sendEvent({
    event: 'ElementClicked',
    nameSpace: 'redventures',
    eventCategory: 'usertracking',
    type: 'v3.ElementClicked',
    webContextNeeded: true,
    payload
  });
}

export function elementViewed(payload) {
  sendEvent({
    event: 'ElementViewed',
    nameSpace: 'redventures',
    eventCategory: 'usertracking',
    type: 'v3.ElementViewed',
    webContextNeeded: true,
    payload
  });
}

export function conversionTracked(payload) {
  sendEvent({
    event: 'ConversionTracked',
    nameSpace: 'core',
    eventCategory: 'conversions',
    type: 'ConversionTracked.v2',
    webContextNeeded: true,
    payload
  });
}

export function conversionMeta(payload) {
  sendEvent({
    event: 'ConversionMeta',
    nameSpace: 'elsewhere',
    eventCategory: 'conversionMeta',
    type: 'v1',
    webContextNeeded: false,
    payload
  });
}

export function sendNewTripRequestEvent({ trip, location, buttonMessage, sender }) {
  const {
    id, // uuid
    tripId, // prettified id
    leaderId,
    destinationName,
    numberOfTravelers,
    title,
    agencyId,
    agencyName,
    agentId,
    agentFirstName,
    agentLastName,
    startDate,
    duration
  } = trip || {};
  const conversionTrackedMetaId = uuidv4();
  if (tagularSet()) {
    window.tagular('setAuthId', leaderId);
  }

  productClicked({
    actionOutcome: 'INTERNALLINK',
    correlationId: tripId,
    listId: '',
    outboundUrl: `${appDomain}trip/details/${id}`,
    product: {
      category: 'Personalized', //Can be either "small group trip" or "personalized"
      location,
      name: destinationName,
      productId: tripId,
      quantity: numberOfTravelers,
      text: buttonMessage
    }
  });
  conversionTracked({
    correlation: {
      id: tripId,
      idEventName: 'redventures.ecommerce.v1.ProductClicked',
      idKey: 'correlationId',
      conversionTrackedMetaId // UUID 32 character to tie to elsewhere.ConversionMeta.v1
    },
    metadata: {
      category: 'Form',
      action: 'Started',
      customName: title,
      timestamp: new Date().toISOString(),
      sender,
      productList: [
        {
          id: tripId,
          price: 0, // total cost of the trip
          currency: 'USD',
          category: 'Personalized', // Can be either "small group trip" or "personalized"
          name: destinationName,
          brand: agencyName
        }
      ],
      conversionSource: {
        tenantId: makeSourceKey,
        name: 'Elsewhere'
      }
    },
    revenue: {
      actualRevenue: {
        amount: 0, // actual payment received
        code: 'USD' // currency code - should be set to "USD" by default
      }
    }
  });
  conversionMeta({
    conversionTrackedMetaId,
    tripId,
    destination: destinationName,
    travelers: numberOfTravelers,
    departureDate: new Date(startDate).toISOString(),
    agent: agentFirstName && agentLastName ? `${agentFirstName} ${agentLastName}` : null,
    agentId: agentId ? agentId.toString() : null,
    agency: agencyName,
    agencyId: agencyId ? agencyId.toString() : null,
    currentStatus: 'Awaiting Verification',
    percentageRevenueAttribution: 0, // % of revenue attributed to Elsewhere
    totalRevenueAttributed: 0, // Actual amount attributed to elsewhere (in USD)
    tripDuration: duration,
    currencyCode: 'USD' // currency code- eg. "USD"
  });
}

export function sendSignUpEvent({
  user,
  hashedEmail,
  anonymousId,
  sessionId,
  instanceId,
  autofillUsed,
  source,
  status
}) {
  const correlationId = uuidv4(); //generate a unique id that should match with form submitted event

  if (status === cohesion.statuses.success) {
    accountCreated({
      userContext: {
        anonymousId,
        correlationId,
        instanceId,
        sessionId
      },
      customerId: user.id ? user.id.toString() : '',
      hashedEmail,
      authenticationLevel: 'ACCOUNT'
    });
  }

  formSubmitted({
    autofillUsed,
    correlationId,
    formContext: {
      formId: 'Account creation',
      formName: 'Sign up',
      formType: 'User authentication'
    },
    stepContext: {
      stepId: 'Success / Failure',
      stepName: source,
      stepType: status
    }
  });
}

export function sendLogInEvent({
  user,
  hashedEmail,
  anonymousId,
  sessionId,
  instanceId,
  autofillUsed,
  source,
  status
}) {
  const correlationId = uuidv4(); //generate a unique id that should match with form submitted event

  if (status === cohesion.statuses.success) {
    accountLogIn({
      userContext: {
        anonymousId,
        correlationId,
        instanceId,
        sessionId
      },
      customerId: user.id ? user.id.toString() : '',
      loginSource: source,
      loginSourceId: '',
      hashedEmail
    });
  }

  formSubmitted({
    autofillUsed,
    correlationId,
    formContext: {
      formId: 'Account login',
      formName: 'Sign in',
      formType: 'User authentication'
    },
    stepContext: {
      stepId: 'Success / Failure',
      stepName: source,
      stepType: status
    }
  });
}

export function sendLogOutEvent({
  user,
  hashedEmail,
  anonymousId,
  sessionId,
  instanceId,
  autofillUsed,
  source,
  status
}) {
  const correlationId = uuidv4(); //generate a unique id that should match with form submitted event

  formSubmitted({
    autofillUsed,
    correlationId,
    formContext: {
      formId: 'Account logout',
      formName: 'Sign out',
      formType: 'Manual logout'
    },
    stepContext: {
      stepId: 'Success / Failure',
      stepName: 'Log out',
      stepType: status
    }
  });

  if (status === cohesion.statuses.success) {
    accountLogOut({
      userContext: {
        anonymousId,
        correlationId,
        instanceId,
        sessionId
      },
      customerId: user.id ? user.id.toString() : '',
      logoutMethod: source,
      logoutMethodId: '',
      userId: user.id ? user.id.toString() : ''
    });

    //reset authid to empty string once logout event is complete
    if (tagularSet()) {
      window.tagular('setAuthId', '');
    }
  }
}

export function sendAuthEvent({ user, anonymousId, sessionId, instanceId, autofillUsed, source, status }, formType) {
  user = user || {};
  const id = user?.id ? user.id.toString() : '';
  const hashedEmail = hashEmail(user?.email);

  if (tagularSet()) {
    window.tagular('setAuthId', id);
  }

  const payload = {
    user,
    hashedEmail,
    anonymousId,
    sessionId,
    instanceId,
    autofillUsed,
    source,
    status
  };

  switch (formType) {
    case 'signup': {
      sendSignUpEvent(payload);
      break;
    }
    case 'login': {
      sendLogInEvent(payload);
      break;
    }
    case 'logout': {
      sendLogOutEvent(payload);
    }
  }
}
