import axios from 'axios';
import Router from 'next/router';

import Cookies from '@utils/cookies';
import { userTokenCookiesKey } from '@constants/constants';
import routes from '@constants/routes';
import NewApi from '@utils/newapi';

class AuthSession {
  static tokenKey = userTokenCookiesKey;

  static getTokenValue = (ctx) => Cookies.get(AuthSession.tokenKey, ctx);

  static isTokenSet(ctx) {
    const authToken = AuthSession.getTokenValue(ctx);
    return authToken && !!authToken.trim();
  }

  static setHeader(ctx) {
    if (AuthSession.isTokenSet(ctx)) {
      const authToken = AuthSession.getTokenValue(ctx);
      axios.defaults.headers.Authorization = `Token ${authToken}`;
      NewApi.defaults.headers.Authorization = `Token ${authToken}`;
    } else {
      AuthSession.removeHeader();
    }
  }

  static removeHeader() {
    axios.defaults.headers.Authorization = '';
    NewApi.defaults.headers.Authorization = '';
  }

  static set(tokenValue) {
    Cookies.set(AuthSession.tokenKey, tokenValue);
    AuthSession.setHeader();
  }

  static remove(ctx) {
    Cookies.remove(AuthSession.tokenKey, ctx);
    AuthSession.removeHeader();
  }

  static login(token) {
    AuthSession.set(token);
    Router.push(routes.index);
  }
}

export default AuthSession;
