import { flow, types } from 'mobx-state-tree';

import Logger from '@utils/logger';
import Api from '@utils/api';
import apiUrls from '@constants/api-urls';

export const CustomTripStore = types
  .model('CustomTripStore', {
    data: types.frozen({}),
    isLoading: false
  })
  .actions((self) => ({
    fetchDetails: flow(function* ({ customTripId }) {
      try {
        self.isLoading = true;
        const response = yield Api.get(apiUrls.tripbuilder.customtrips.item(customTripId));
        self.data = response.data;
      } catch (e) {
        Logger.error(e);
      } finally {
        self.isLoading = false;
      }
    })
  }));
